$(document).ready(function () {
  $('.show-password').on('click', function (e) {
    const passwordInput = $(this).closest('.password-container').find('.user-password');
    if (passwordInput.attr('type') === 'password') {
      passwordInput.attr('type', 'text');
      $(this).removeClass('fa-eye').addClass('fa-eye-slash');
    } else {
      passwordInput.attr('type', 'password');
      $(this).removeClass('fa-eye-slash').addClass('fa-eye');
    }
  });
});