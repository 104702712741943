$(document).ready(function () {
  // Mobile menu toggle functionality
  $('.mobile-menu-toggle').on('click', function (e) {
    e.preventDefault();
    
    // Toggle active class on navigation
    $('.main-navigation').toggleClass('active');
    
    // Toggle active class on the button itself
    $(this).toggleClass('active');
    
    // Animate hamburger to X
    if ($(this).hasClass('active')) {
      $(this).find('.bar:nth-child(1)').css('transform', 'rotate(45deg) translate(5px, 6px)');
      $(this).find('.bar:nth-child(2)').css('opacity', '0');
      $(this).find('.bar:nth-child(3)').css('transform', 'rotate(-45deg) translate(5px, -6px)');
    } else {
      $(this).find('.bar:nth-child(1)').css('transform', 'none');
      $(this).find('.bar:nth-child(2)').css('opacity', '1');
      $(this).find('.bar:nth-child(3)').css('transform', 'none');
    }
  });
  
  // Close mobile menu when clicking outside
  $(document).on('click', function (e) {
    if ($('.main-navigation').hasClass('active')) {
      if (!$(e.target).closest('.main-navigation').length && !$(e.target).closest('.mobile-menu-toggle').length) {
        $('.main-navigation').removeClass('active');
        
        if ($('.mobile-menu-toggle').hasClass('active')) {
          $('.mobile-menu-toggle').removeClass('active');
          $('.mobile-menu-toggle').find('.bar:nth-child(1)').css('transform', 'none');
          $('.mobile-menu-toggle').find('.bar:nth-child(2)').css('opacity', '1');
          $('.mobile-menu-toggle').find('.bar:nth-child(3)').css('transform', 'none');
        }
      }
    }
  });
  
  // Handle window resize
  $(window).on('resize', function () {
    if ($(window).width() > 991 && $('.main-navigation').hasClass('active')) {
      $('.main-navigation').removeClass('active');
      
      if ($('.mobile-menu-toggle').hasClass('active')) {
        $('.mobile-menu-toggle').removeClass('active');
        $('.mobile-menu-toggle').find('.bar:nth-child(1)').css('transform', 'none');
        $('.mobile-menu-toggle').find('.bar:nth-child(2)').css('opacity', '1');
        $('.mobile-menu-toggle').find('.bar:nth-child(3)').css('transform', 'none');
      }
    }
  });
  
  // User account dropdown toggle (optional)
  $('.account-toggle').on('click', function (e) {
    e.preventDefault();
    $(this).next('.account-dropdown').toggleClass('show');
  });
  
  // Close dropdown when clicking outside
  $(document).on('click', function (e) {
    if (!$(e.target).closest('.user-account').length) {
      $('.account-dropdown').removeClass('show');
    }
  });
});
